//colors constants
$color_black: #222222
$color_black_medium: #161616
$color_black_light: #090909
$color_grey: #999999
$color_grey_light: #C4C4C4
$color_grey_vivid: #eeeeee
$color_white: #FFFFFF
$color_white2: #F8FFFF
$color_cyan: #51B5AE
$color_cyan_light: #75D2CC
$color_red: #FF6565
$color_brown: #7D5D54
$color_brown_light: #A7857C
$color_dark: #333333
$color_dark_light: #444444
$color_black_medium_opacitied: rgba(22, 22, 22, 0.1)

//resolution constants
$mobile_resolution: 320px
$tablet_resolution: 768px
$small_desktop_resolution: 1024px
$before_desktop_resolution: 1180px
$medium_desktop_resolution: 1265px
$desktop_resolution: 1366px
$fullhd_resolution: 1920px
$more_desktop_resolution: 1640px

//leftMenuSidebarWidth
$menuWidth: 190px
$menuWidthDesctop: 250px
$menuWidthHd: 350px
