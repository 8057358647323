//Montserrat bold
@font-face
  font-family: 'Montserrat-bold'
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype')
  font-style: normal
  font-weight: 700

//Montserrat medium
@font-face
  font-family: 'Montserrat-medium'
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype')
  font-style: normal
  font-weight: 500

//Montserrat Regular
@font-face
  font-family: 'Montserrat-regular'
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype')
  font-style: normal
  font-weight: 400

//Montserrat light
@font-face
  font-family: 'Montserrat-light'
  src: url('./fonts/Montserrat-Light.ttf') format('truetype')
  font-style: normal
  font-weight: 300
