@import './fonts'
@import '~antd/dist/antd.css'
@import 'variables'
@import '../node_modules/reset-css/sass/reset'

body
  line-height: 1.3
  overflow-x: hidden

.overflow-y
  height: 100%
  overflow-y: scroll

.overflow-x
  overflow-x: scroll

#root
  height: 100%

button
  border: none
  outline: none
  background-color: inherit
  cursor: pointer

.btn
  border: none
  outline: none
  width: 30px
  height: 30px
  padding: 0
  background-color: inherit
  cursor: pointer

.container
  width: 100%
  @media screen and (min-width: $tablet_resolution)
    padding: 14px 14px
    background-color: $color_black_medium

.container-wrapper
  @media screen and (min-width: $mobile_resolution)
    padding: 50px 10px

  @media screen and (min-width: $tablet_resolution)
    padding: 60px 75px

  @media screen and (min-width: $small_desktop_resolution)
    padding: 100px 80px

  @media screen and (min-width: $desktop_resolution)
    padding: 100px 110px

  @media screen and (min-width: $fullhd_resolution)
    padding: 120px 155px

a
  font-family: Montserrat-medium
  &:hover
    color: $color_white
  @media screen and (min-width: $mobile_resolution)
    font-size: 12px

  @media screen and (min-width: $tablet_resolution)
    font-size: 13px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 14px

h1
  font-family: Montserrat-bold
  text-transform: uppercase
  @media screen and (min-width: $mobile_resolution)
    font-size: 28px

  @media screen and (min-width: $tablet_resolution)
    font-size: 34px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 56px

h2
  font-family: Montserrat-medium
  @media screen and (min-width: $mobile_resolution)
    font-size: 20px
  @media screen and (min-width: $fullhd_resolution)
    font-size: 24px

h3,
.main-title
  font-family: Montserrat-bold
  text-transform: uppercase
  @media screen and (min-width: $mobile_resolution)
    font-size: 22px

  @media screen and (min-width: $tablet_resolution)
    font-size: 24px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 36px

h6
  font-family: Montserrat-medium
  @media screen and (min-width: $mobile_resolution)
    font-size: 16px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 18px

h5
  font-family: Montserrat-medium
  @media screen and (min-width: $mobile_resolution)
    font-size: 18px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 20px

.subtitle-2
  font-family: Montserrat-medium

  @media screen and (min-width: $mobile_resolution)
    font-size: 13px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 14px

.subtitle-1
  font-family: Montserrat-medium

  @media screen and (min-width: $mobile_resolution)
    font-size: 14px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 16px

.subtitle-1-light
  font-family: Montserrat-light

  @media screen and (min-width: $mobile_resolution)
    font-size: 14px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 16px

.body-light
  font-family: Montserrat-light

  @media screen and (min-width: $mobile_resolution)
    font-size: 13px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 14px

  b
    font-family: 'Montserrat-bold'

.body-bold
  p
    font-family: 'Montserrat-bold'

.caption
  font-family: Montserrat-regular
  @media screen and (min-width: $mobile_resolution)
    font-size: 11px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 12px

.date
  color: $color_grey_light
  font-family: Montserrat-light

  @media screen and (min-width: $mobile_resolution)
    font-size: 11px

  @media screen and (min-width: $fullhd_resolution)
    font-size: 12px

.ant-spin
  position: absolute
  top: 50%
  left: 47%
  z-index: 2000

.ant-spin-dot-item
  background: $color_cyan

// Remove browsers style for autocomplete
// https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
  -webkit-box-shadow: 0 0 0 30px $color_black_medium inset !important
  -webkit-text-fill-color: $color_white !important

::-webkit-scrollbar
  display: none 
  width: 10px
  background-color: rgba(196, 196, 196, 0.2)
  @media screen and (min-width: $small_desktop_resolution)
    display: block

::-webkit-scrollbar-track
  background-color: rgba(196, 196, 196, 0.2)

::-webkit-scrollbar-thumb
  background-color: rgba(117, 210, 204, 0.8)


.ant-message
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 2000

  .ant-message-notice-content
    font-family: Montserrat-medium
    font-size: 16px
    width: 200px
    min-height: 50px
    height: auto
    background-color: $color_dark
    color: $color_white

.pagination
  display: flex
  justify-content: space-between
  max-width: 300px
  margin: 0 auto
  .ant-pagination-item
    border: none
    a
      color: $color_white
      transition: color ease 0.2s
      &:hover
        color: $color_cyan

  .ant-pagination-item-active
    background-color: transparent
    a
      color: $color_cyan

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-prev a,
  .ant-pagination-next a
    color: $color_white

  // .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  // .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis
  //   color: $color_white

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon
    color: $color_cyan
  
  .ant-pagination-prev a,
  .ant-pagination-next a
    &:hover
      color: $color_cyan

.extra-padding
  padding-bottom: 190px
  @media screen and (min-width: $small_desktop_resolution)
    padding-bottom: 140px

.btn-back
  position: fixed
  width: 100px
  background-image: url('/assets/arrow-back.svg')
  background-repeat: no-repeat
  background-position: center left
  top: 5px
  left: 10px
  background-color: transparent
  color: $color_grey
  &.subtitle-2
    color: $color_grey
  @media screen and (min-width: $small_desktop_resolution)
    position: absolute
    top: 20px
    left: 20px
    opacity: 1